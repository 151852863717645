
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				roleid: '',
				shouldreadonly: true,
				keyword: "",
				password: false,
				isloading: false,
				edtdirect: 'rtl',
				editopinfo: false,
				editortitle: this.PAGELANG.ziliaobianji,
				curselname: "tab0",
				roles: [],
				oprolelist: [],
				oprolelst: [],
				ptreeselids: [],
				permittree: [],
				props: {
					label: 'PermitName',
					children: 'LHSTChildren',
					disabled: this.disableFunc
				},
				edtprofile: {
					ID: 0,
					OpName: '',
					OpMobile: '',
					Passwd: '',
					CfmPasswd: '',
					Email: '',
					Status: true,
				},
				rule: {
					OpMobile: [{
						required: true,
						message: this.PAGELANG.qtxsj,
						trigger: 'blur'
					}],
					OpName: [{
						required: true,
						message: this.PAGELANG.qtxxm,
						trigger: 'blur'
					}],
					RoleID: [{
						required: false,
						message: this.PAGELANG.qxzygjs,
						trigger: 'blur'
					}]
				},
				tabledata: [],
				passwordinfo: {
					id: '',
					newpassword: '',
					confirmpassword: '',
				},
				passwordrule: {
					newpassword: [{
						required: true,
						message: ' ',
						trigger: 'blur'
					}],
					confirmpassword: [{
						required: true,
						message: ' ',
						trigger: 'blur'
					}]
				}
			}
		},
		watch: {
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						that.timerw = false
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						that.timerh = false;
					}, 400)
				}
			}
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Permission;
		},
		methods: {
			onEdtTabClicked(evt) {
				console.log(evt);
				//if(evt.paneName == 'tab2') {
				//	this.RefreshPermits();
				//}
			},
			disableFunc() {
				if(this.edtprofile.RoleID == '5')
					return true;
				else 
					return false;
			},
			onRoleChanged() {
				console.log(this.oprolelst);
				this.RefreshPermits();
			},
			handleCheckChange(data, checked, indeterminate) {
				console.log(data, checked, indeterminate);
				if (checked) {
					if(this.ptreeselids.indexOf(data.ID) < 0)
						this.ptreeselids.push(data.ID);
				}
				else if (this.ptreeselids.indexOf(data.ID) >= 0)
					this.ptreeselids.splice(this.ptreeselids.indexOf(data.ID), 1);
				console.log(this.ptreeselids);
			},
			SaveProfile() {
				if(this.LoadingPermit) {
					ElMessage({
						message: this.PAGELANG.zzclsjqsh,
						type: "warning"
					})
				}
				//console.log(this.edtprofile);
				if (!this.edtprofile.ID && !this.edtprofile.Passwd) {
					ElMessage({
						message: this.PAGELANG.mmbnwk,
						type: "error"
					})
				}
				if (this.edtprofile.Passwd && this.edtprofile.Passwd != this.edtprofile.CfmPasswd) {
					ElMessage({
						message: this.PAGELANG.mmsrbyz,
						type: "error"
					})
				}
								
				let postdata = {
					oprolelst: this.oprolelst,
					ptreeselids: this.ptreeselids
				}
				
				for(var key in this.edtprofile) {
					postdata[key] = this.edtprofile[key];
				}
				
				console.log(postdata);

				this.axios.post(this.actions.saveop, postdata).then((response) => {
					console.log(response.data);
					if (response.data == "OK") {
						this.$message.success(this.PAGELANG.caozuochenggong);
						this.$router.go(0);
					}
				})
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
			CloseDrawer() {
				this.editopinfo = false;
			},
			Delete(delid) {
				console.log(delid);
				ElMessageBox.confirm(this.PAGELANG.nqdyscgczym, {
						type: 'warning'
					})
					.then(() => {
						console.log('ok');
						this.axios.post(this.actions.delop, {
							"id": delid
						}, {
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded'
							}
						}).then((response) => {
							console.log(response.data);
							if (response.data == "OK") {
								this.$message.success(this.PAGELANG.qxzygjs);
								this.$router.go(0);
							}
						})
					})
					.catch((err) => {
						// catch error
						console.log(err);
					})
			},
			Refresh() {
				this.GetOpList();
				this.GetRoleList();

			},
			AddRole() {
				this.$router.push("/settings/role");
			},
			Search() {
				this.Refresh();
			},
			CreateAccount() {
				//先初始化
				this.edtprofile = {
					ID: 0,
					OpName: '',
					OpMobile: '',
					Email: '',
					Status: true,
				}
				this.shouldreadonly = false;
				this.editopinfo = true;
				this.curselname = "tab0";
				this.oprolelst = [];
				this.ptreeselids = [];
				
				this.axios.get(this.actions.oprolepermits, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
					}
				}).then((response) => {
					console.log(response.data);
					this.isloading = false;
					this.oprolelist = response.data.oprolelist;
					
					this.permittree = response.data.permittree;

					this.editopinfo = true;
					
					//this.RefreshPermits();
				});
			},
			ClosePWDlg() {
				this.password = false;
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			},
			ResetPassword(id) {
				this.passwordinfo.id = id;
				this.password = true;
			},
			GetRoleList() {
				this.isloading = true;
				this.axios.get(this.actions.rolelist, {
					headers: {
						"Content-Type": "application/json",
					},
				}).then((response) => {
					this.isloading = false;
					console.log(response.data);
					this.roles = response.data;
				});
			},
			GetOpList() {
				this.axios.get(this.actions.oplist, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						roleid: this.roleid,
						keyword: this.keyword,
					}
				}).then((response) => {
					console.log(response.data);
					this.tabledata = response.data;
				})
			},
			Edit(id) {
				if (!id) {
					return false;
				}
				
				this.EditingOpID = id;

				this.isloading = true;

				this.axios.get(this.actions.editop, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: id,
					}
				}).then((response) => {
					console.log(response.data);
					this.isloading = false;
					this.editortitle = this.PAGELANG.zlbjq + ' - ' + response.data.profile.OpName;
					this.edtprofile = response.data.profile;
					this.oprolelist = response.data.oprolelist;
					this.edtprofile.Status = (this.edtprofile.Status == 1 ? true : false);
					/*this.permittree = response.data.permittree;
					this.ptreeselids = response.data.ptreeselids;
					
					if(this.$refs.permittree)
						this.$refs.permittree.setCheckedKeys(this.ptreeselids);*/
						
					let oprlst = Array();
					
					for(var i=0;i<this.oprolelist.length;++i) {
						if(this.oprolelist[i].SelID) {
							oprlst.push(this.oprolelist[i].ID);
						}
					}
					
					this.oprolelst = oprlst;
					
					//console.log(this.ptreeselids)

					this.shouldreadonly = true;
					this.editopinfo = true;
					
					this.RefreshPermits(id);
				});
			},
			RefreshPermits(id) {
				this.permittree = [];
				this.ptreeselids = [];
				this.LoadingPermit = true;
				if(!id)
					id = this.EditingOpID;
					
				this.axios.post(this.actions.oppermits, {
						id: id,
						oprolelst: this.oprolelst
					}, {
					headers: {
						"Content-Type": "application/json",
					} 
				}).then((response) => {
					this.LoadingPermit = false;
					console.log(response.data);
					this.permittree = response.data.permittree;
					this.ptreeselids = response.data.ptreeselids;
					
					if(this.$refs.permittree)
						this.$refs.permittree.setCheckedKeys(this.ptreeselids);
				});
			},
			UpdateStatus(id, status) {
				if (!id) {
					return;
				}

				this.axios.get(this.actions.updatestatus, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: id,
						status: status,
					}
				}).then((response) => {
					console.log(response.data);
					if (response.data == "OK") {
						this.$message.success("操作成功");
					}
				})
			},
			SavePassword() {
				if (this.passwordinfo.newpassword != this.passwordinfo.confirmpassword) {
					this.$message.error("请确认新密码");
					return false;
				}
				this.$refs['passwordinfo'].validate((valid) => {
					if (valid) {
						this.axios.post(this.actions.savepass, this.passwordinfo, {
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded'
							}
						}).then((response) => {
							console.log(response.data);
							this.ClosePWDlg();
							this.$refs['passwordinfo'].resetFields()
							if (response.data == "OK") {
								this.$message.success("操作成功");
							}
						})
					} else {
						return false;
					}
				})
			}
		},
		created: function() {
			window.addEventListener('resize', this.handleResize)
			this.Refresh();
		},
		components: {}
	}
